<div class="cta-area ptb-100">
    <div class="container">
        <div class="cta-content">
            <div class="section-title mb-0">
                <h4>¿Buscando un excelente servicio?</h4>
                <h2>Contáctanos Ahora</h2>
                <p>Nuestra experiencia en el desarrollo es justo lo que necesita tu proyecto.</p>
                <a (click)="onClick('contact')" class="btn btn-primary">Contacto</a>
                <a (click)="onClick('work')" class="btn btn-primary view-work">Nuestro portafolio</a>
            </div>
        </div>
    </div>
</div>
