<div class="strategy-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="strategy-image">
                    <img src="assets/img/newStrategy2.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="strategy-content ptb-70">
                    <div class="section-title">
                        <h4>Estas son nuestras</h4>
                        <h2>Áreas de <span>Experiencia</span></h2>
                    </div>
                    <ul class="features-list">
                        <li><i class="fa fa-check"></i>Diseño responsivo</li>
                        <li><i class="fa fa-check"></i>Manejo de infraestructura</li>
                        <li><i class="fa fa-check"></i>Retroalimentación rápida</li>
                        <li><i class="fa fa-check"></i>Despliegue de sitios</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>
