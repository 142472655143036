<div id="services" class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Nuestros <span>Servicios</span></h2>
            <p>Consulta aquí en detalle nuestras ofertas en productos tecnológicos y servicios para tu marca o negocio.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img1.jpg" alt="services-img">
                        <div class="icon">
                            <i class="fas fa-pencil-alt"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Diseño web</h3>
                        <p>Solicita una cotización para obtener un diseño de tu sitio web, para tu negocio o personal. 100% responsivo.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img2.jpg" alt="services-img">
                        <div class="icon">
                            <i class="fab fa-linode"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Desarrollo web</h3>
                        <p>A partir de un diseño existente o de nuestra autoría, completamos tu sitio web acorde a todas las especificaciones.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img3.jpg" alt="services-img">
                        <div class="icon">
                            <i class="fas fa-desktop"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Infraestructura</h3>
                        <p>POdemos diseñar e implementar una solución de software para tus procesos de negocio.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img4.jpg" alt="services-img">
                        <div class="icon">
                            <i class="fas fa-chart-line"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Analítica</h3>
                        <p>Próximamente</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img5.jpg" alt="services-img">
                        <div class="icon">
                            <i class="fas fa-anchor"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Desarrollo Móvil</h3>
                        <p>Queremos darle a tu negocio la app que se merece tener.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img6.jpg" alt="services-img">
                        <div class="icon">
                            <i class="fas fa-headphones-alt"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Servidores</h3>
                        <p>Tenemos la capacidad de desarrollar servicios que puede emplear tu app ya existente.</p>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6">
                 <div class="single-services">
                 <div class="services-img">
                 <img src="assets/img/services-img7.jpg" alt="services-img">
                 <div class="icon">
                 <i class="fas fa-mobile-alt"></i>
                 </div>
                 </div>
                 <div class="services-content">
                 <h3>Responsive Design</h3>
                 <p>Lorem ipsum dolor sit amet consecte, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                 </div>
                 </div>
                 </div>
                 <div class="col-lg-4 col-md-6">
                 <div class="single-services">
                 <div class="services-img">
                 <img src="assets/img/services-img8.jpg" alt="services-img">
                 <div class="icon">
                 <i class="fas fa-camera-retro"></i>
                 </div>
                 </div>
                 <div class="services-content">
                 <h3>Photography</h3>
                 <p>Lorem ipsum dolor sit amet consecte, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                 </div>
                 </div>
                 </div>
                 <div class="col-lg-4 col-md-6">
                 <div class="single-services">
                 <div class="services-img">
                 <img src="assets/img/services-img9.jpg" alt="services-img">
                 <div class="icon">
                 <i class="far fa-life-ring"></i>
                 </div>
                 </div>
                 <div class="services-content">
                 <h3>Custom Support</h3>
                 <p>Lorem ipsum dolor sit amet consecte, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                 </div>
                 </div>
                 </div> -->
        </div>
    </div>
</div>
