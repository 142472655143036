<div class="who-we-are-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Nuestros <span>Valores</span></h2>
            <p>
                Somos una empresa comprometida con la entrega puntal y completa de todos nuestros productos, y de proveer un soporte
                exhaustivo para garantizar la satisfacción del cliente.
            </p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-users"></i>
                    <h3>Profesionalismo</h3>
                    <p>
                        Nos caracterizamos por un fuerte sentido de la responsabilidad. Tu proyecto está garantizado con Tomorrow Tech.
                    </p>
                    <span>1</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-lightbulb"></i>
                    <h3>Amplios conocimientos</h3>
                    <p>
                        Contamos con expertos en el diseño, desarrollo y ejecución de proyectos de software, apoyados siempre en
                        las mejores tecnologías y buenas prácticas.
                    </p>
                    <span>2</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-tag"></i>
                    <h3>Diligencia</h3>
                    <p>
                        Nos enorgullecemos de trabajar duro e investigar lo necesario para asegurar una alta calidad en cada producto.
                    </p>
                    <span>3</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-hand-scissors"></i>
                    <h3>Calidez</h3>
                    <p>
                        Somos un equipo muy humano. Nos encantará conocerte y trabajar contigo.
                    </p>
                    <span>4</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fab fa-linode"></i>
                    <h3>Honestidad</h3>
                    <p>
                        La base de todo nuestro trabajo en Tomorrow Tech es la transparencia, en nuestros procesos de desarrollo y
                        nuestra relación con los clientes y proveedores.
                    </p>
                    <span>5</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-life-ring"></i>
                    <h3>Servicialidad</h3>
                    <p>
                        Nuestro mayor placer es saber que nuestro cliente ha quedado satisfecho con nuestro trabajo.
                    </p>
                    <span>6</span>
                </div>
            </div>
        </div>
    </div>
</div>
