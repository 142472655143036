<section class="how-work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Nuestro <span>Proceso</span></h2>
            <p>
                Conoce la metodología ágil que energiza nuestro proceso de desarrollo y qué la hace diferente y óptima para colaborar con nuestros
                clientes, permitiéndonos hacer entrega de software productivo en el menor tiempo posible.
            </p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-info-circle"></i>
                    </div>
                    <h3>Comunicación primero</h3>
                    <p>
                        La comunicación es esencial para nuestro proceso y en toda etapa de desarrollo, nos aseguraremos de ir
                        en la dirección correcta.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-drafting-compass"></i>
                    </div>
                    <h3>Diseño continuo</h3>
                    <p>
                        Mediante la revisión continua, nos aseguramos de que el producto final sea óptimo en cuanto a
                        apariencias, funcionalidad y especficaciones.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="far fa-paper-plane"></i>
                    </div>
                    <h3>Entrega continua</h3>
                    <p>
                        Ten en la mano un prototipo funcional desde la primera semana.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</section>
