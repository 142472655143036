<div class="why-we-different ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2>Qué nos <span>hace diferentes</span></h2>
            <p>Nuestra empresa cuenta con todo lo necesario para garantizar el éxito de su producto.</p>
        </div>
        <div class="tabset why-we-different-tabset">
            <!-- Tab 1 -->
            <input type="radio" name="tabset" id="tab1" aria-controls="speed-flexibility" checked>
            <label for="tab1">Velocidad y Flexibilidad</label>
            <!-- Tab 2 -->
            <input type="radio" name="tabset" id="tab2" aria-controls="profeesional-work">
            <label for="tab2">100% Profesional</label>
            <!-- Tab 3 -->
            <input type="radio" name="tabset" id="tab3" aria-controls="experienced-staff">
            <label for="tab3">Equipo Experimentado</label>
            <!-- Tab 4 -->
            <input type="radio" name="tabset" id="tab4" aria-controls="custom-support">
            <label for="tab4">Soporte Personalizado</label>
            <div class="tab-panels">
                <div id="speed-flexibility" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-img2.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Velocidad y Flexibilidad</h3>
                                <p>
                                    Nuestra metolodogía enfatiza la entrega temprana y constante y tenemos la disposición de aceptar cambios
                                    durante el desarrollo del proyecto.
                                </p>
                                <!-- <ul>
                                     <li><i class="fas fa-check"></i> Creative Design</li>
                                     <li><i class="fas fa-check"></i> Retina Ready</li>
                                     <li><i class="fas fa-check"></i> Responsive Design</li>
                                     <li><i class="fas fa-check"></i> Modern Design</li>
                                     <li><i class="fas fa-check"></i> Awesome Design</li>
                                     </ul> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div id="profeesional-work" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-img1.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>100% Profesional</h3>
                                <p>Trabajo duro, responsable y detallista. Tomorrow Tech.</p>
                                <!-- <ul>
                                     <li><i class="fas fa-check"></i> Creative Design</li>
                                     <li><i class="fas fa-check"></i> Retina Ready</li>
                                     <li><i class="fas fa-check"></i> Responsive Design</li>
                                     <li><i class="fas fa-check"></i> Modern Design</li>
                                     <li><i class="fas fa-check"></i> Awesome Design</li>
                                     </ul> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div id="experienced-staff" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-img3.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Equipo Experimentado</h3>
                                <p>Nuestro equipo cuenta con años de experiencia en el desarrollo y el uso de tecnologías. Puedes contar con nosotros en las tareas más complicadas.</p>
                                <!-- <ul>
                                     <li><i class="fas fa-check"></i> Creative Design</li>
                                     <li><i class="fas fa-check"></i> Retina Ready</li>
                                     <li><i class="fas fa-check"></i> Responsive Design</li>
                                     <li><i class="fas fa-check"></i> Modern Design</li>
                                     <li><i class="fas fa-check"></i> Awesome Design</li>
                                     </ul> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div id="custom-support" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-img4.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Soporte Personalizado</h3>
                                <p>Puedes contar con nosotros para atender todo tipo de situaciones en el área tecnológica.</p>
                                <!-- <ul>
                                     <li><i class="fas fa-check"></i> Creative Design</li>
                                     <li><i class="fas fa-check"></i> Retina Ready</li>
                                     <li><i class="fas fa-check"></i> Responsive Design</li>
                                     <li><i class="fas fa-check"></i> Modern Design</li>
                                     <li><i class="fas fa-check"></i> Awesome Design</li>
                                     </ul> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
