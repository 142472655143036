<footer class="footer-area">
    <div class="container">
        <h3><a routerLink="/"><span>t</span>OMORROW <span>t</span>ECH</a></h3>
        <ul>
            <!-- <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
                 <li><a href="#" target="_blank" class="fab fa-twitter"></a></li> -->
            <li><a href="https://www.linkedin.com/company/tomorrow-tech-s-a-s/" target="_blank" class="fab fa-linkedin-in"></a></li>
            <!-- <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
                 <li><a href="#" target="_blank" class="fab fa-skype"></a></li> -->
        </ul>
        <p>Copyright <i class="far fa-copyright"></i>2021 All Rights Reserved.</p>
    </div>
</footer>

<!-- <app-demo-sidebar></app-demo-sidebar> -->

<ngx-scrolltop></ngx-scrolltop>
