<section class="funfacts-area pt-100 pb-70">
    <div class="container">
        <!-- <div class="row">
             <div class="col-lg-3 col-md-3 col-6 col-sm-3">
             <div class="funfact">
             <i class="far fa-smile"></i>
             <h3><span [countUp]="5">00</span></h3>
             <p>Happy Clients</p>
             </div>
             </div>
             <div class="col-lg-3 col-md-3 col-6 col-sm-3">
             <div class="funfact">
             <i class="fas fa-shield-alt"></i>
             <h3><span [countUp]="6">00</span></h3>
             <p>Completed Projects</p>
             </div>
             </div>
             <div class="col-lg-3 col-md-3 col-6 col-sm-3">
             <div class="funfact">
             <i class="fas fa-award"></i>
             <h3><span [countUp]="46">00</span></h3>
             <p>Winning Awards</p>
             </div>
             </div>
             <div class="col-lg-3 col-md-3 col-6 col-sm-3">
             <div class="funfact">
             <i class="fas fa-user"></i>
             <h3><span [countUp]="3">00</span></h3>
             <p>Team Members</p>
             </div>
             </div>
             </div> -->
    </div>
</section>
