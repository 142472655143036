<div id="about" class="about-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Sobre <span>Nosotros</span></h2>
            <p>Tomorrow Tech se compromete con sus clientes a entrar productos con los más altos estándares de calidad al mejor precio.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>Tenemos experiencia en <span>Diseño y Desarrollo</span></h2>
                        <p>
                            En Tomorrow Tech, nuestra misión es la de proveer soluciones tecnológicas a nuestros clientes. Estos son algunos
                            de nuestros productos y servicios, que incluyen pero no se limitan a:
                        </p>
                    </div>
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Diseño de interfaces gráficas</li>
                        <li><i class="fas fa-check"></i>Páginas y aplicaciones web</li>
                        <li><i class="fas fa-check"></i>Aplicaciones nativas (iOS y Android)</li>
                        <li><i class="fas fa-check"></i>Despliegue de sitios y manejo de dominios</li>
                        <li><i class="fas fa-check"></i>Consultoría en temas varios</li>
                    </ul>
                    <p>No dudes en consultarnos para poder ofrecerte una cotización completa.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <img src="assets/img/aboutNew.jpg" alt="about">
                    <div class="video-btn">
                        <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="fas fa-play"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="video-popup">
    <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal>
</div>
