<div id="work" class="work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Nuestro <span>Trabajo</span></h2>
            <p>Estos son sólo algunos de nuestros trabajos, recibidos a satisfacción por nuestros clientes.</p>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
        </div>
    </div>
    <div class="container-fluid">
        <div class="work-tabs">
            <ngx-tabset>
                <ngx-tab tabTitle="All">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Tecnogold S.A.S.</h3>
                                    <!-- <ul>
                                         <li><a routerLink="/"></a></li>
                                         <li> - </li>
                                         <li><a routerLink="/">Marca</a></li>
                                         </ul> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img2.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Ultraemeralds S.A.S.</h3>
                                    <!-- <ul>
                                         <li><a href="#"></a></li>
                                         <li> . </li>
                                         <li><a href="#">Games</a></li>
                                         </ul> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img3.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Asociación de chocoanos nacionales unidos de Colombia</h3>
                                    <!-- <ul>
                                         <li><a routerLink="/">Design</a></li>
                                         <li>.</li>
                                         <li><a routerLink="/">Brand</a></li>
                                         </ul> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img4.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Gold Tracking App</h3>
                                    <!-- <ul>
                                         <li><a routerLink="/">Design</a></li>
                                         <li>.</li>
                                         <li><a routerLink="/">Brand</a></li>
                                         </ul> -->
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-lg-4 col-md-6">
                             <div class="single-work">
                             <img src="assets/img/work-img5.jpg" alt="work-img">
                             <div class="work-content">
                             <h3>Creative Design</h3>
                             <ul>
                             <li><a routerLink="/">Design</a></li>
                             <li>.</li>
                             <li><a routerLink="/">Brand</a></li>
                             </ul>
                             </div>
                             </div>
                             </div>
                             <div class="col-lg-4 col-md-6">
                             <div class="single-work">
                             <img src="assets/img/work-img6.jpg" alt="work-img">
                             <div class="work-content">
                             <h3>Creative Design</h3>
                             <ul>
                             <li><a routerLink="/">Design</a></li>
                             <li>.</li>
                             <li><a routerLink="/">Brand</a></li>
                             </ul>
                             </div>
                             </div>
                             </div> -->
                    </div>
                </ngx-tab>
                <!-- <ngx-tab tabTitle="Brand">
                     <div class="row">
                     <div class="col-lg-4 col-md-6">
                     <div class="single-work">
                     <img src="assets/img/work-img1.jpg" alt="work-img">
                     <div class="work-content">
                     <h3>Creative Design</h3>
                     <ul>
                     <li><a routerLink="/">Design</a></li>
                     <li> . </li>
                     <li><a routerLink="/">Brand</a></li>
                     </ul>
                     </div>
                     </div>
                     </div>
                     <div class="col-lg-4 col-md-6">
                     <div class="single-work">
                     <img src="assets/img/work-img6.jpg" alt="work-img">
                     <div class="work-content">
                     <h3>Creative Design</h3>
                     <ul>
                     <li><a routerLink="/">Design</a></li>
                     <li>.</li>
                     <li><a routerLink="/">Brand</a></li>
                     </ul>
                     </div>
                     </div>
                     </div>
                     </div>
                     </ngx-tab> -->
                <!-- <ngx-tab tabTitle="Design">
                     <div class="row">
                     <div class="col-lg-4 col-md-6">
                     <div class="single-work">
                     <img src="assets/img/work-img1.jpg" alt="work-img">
                     <div class="work-content">
                     <h3>Creative Design</h3>
                     <ul>
                     <li><a routerLink="/">Design</a></li>
                     <li> . </li>
                     <li><a routerLink="/">Brand</a></li>
                     </ul>
                     </div>
                     </div>
                     </div>
                     <div class="col-lg-4 col-md-6">
                     <div class="single-work">
                     <img src="assets/img/work-img2.jpg" alt="work-img">
                     <div class="work-content">
                     <h3>Games Design</h3>
                     <ul>
                     <li><a href="#">IT</a></li>
                     <li> . </li>
                     <li><a href="#">Games</a></li>
                     </ul>
                     </div>
                     </div>
                     </div>
                     </div>
                     </ngx-tab> -->
                <!-- <ngx-tab tabTitle="Graphic">
                     <div class="row">
                     <div class="col-lg-4 col-md-6">
                     <div class="single-work">
                     <img src="assets/img/work-img2.jpg" alt="work-img">
                     <div class="work-content">
                     <h3>Games Design</h3>
                     <ul>
                     <li><a href="#">IT</a></li>
                     <li> . </li>
                     <li><a href="#">Games</a></li>
                     </ul>
                     </div>
                     </div>
                     </div>
                     <div class="col-lg-4 col-md-6">
                     <div class="single-work">
                     <img src="assets/img/work-img3.jpg" alt="work-img">
                     <div class="work-content">
                     <h3>Creative Design</h3>
                     <ul>
                     <li><a routerLink="/">Design</a></li>
                     <li>.</li>
                     <li><a routerLink="/">Brand</a></li>
                     </ul>
                     </div>
                     </div>
                     </div>
                     </div>
                     </ngx-tab> -->
                <!-- <ngx-tab tabTitle="Photoshop">
                     <div class="row">
                     <div class="col-lg-4 col-md-6">
                     <div class="single-work">
                     <img src="assets/img/work-img3.jpg" alt="work-img">
                     <div class="work-content">
                     <h3>Creative Design</h3>
                     <ul>
                     <li><a routerLink="/">Design</a></li>
                     <li>.</li>
                     <li><a routerLink="/">Brand</a></li>
                     </ul>
                     </div>
                     </div>
                     </div>
                     <div class="col-lg-4 col-md-6">
                     <div class="single-work">
                     <img src="assets/img/work-img4.jpg" alt="work-img">
                     <div class="work-content">
                     <h3>Creative Design</h3>
                     <ul>
                     <li><a routerLink="/">Design</a></li>
                     <li>.</li>
                     <li><a routerLink="/">Brand</a></li>
                     </ul>
                     </div>
                     </div>
                     </div>
                     </div>
                     </ngx-tab> -->
                <!-- <ngx-tab tabTitle="Illustrator">
                     <div class="row">
                     <div class="col-lg-4 col-md-6">
                     <div class="single-work">
                     <img src="assets/img/work-img4.jpg" alt="work-img">
                     <div class="work-content">
                     <h3>Creative Design</h3>
                     <ul>
                     <li><a routerLink="/">Design</a></li>
                     <li>.</li>
                     <li><a routerLink="/">Brand</a></li>
                     </ul>
                     </div>
                     </div>
                     </div>
                     <div class="col-lg-4 col-md-6">
                     <div class="single-work">
                     <img src="assets/img/work-img5.jpg" alt="work-img">
                     <div class="work-content">
                     <h3>Creative Design</h3>
                     <ul>
                     <li><a routerLink="/">Design</a></li>
                     <li>.</li>
                     <li><a routerLink="/">Brand</a></li>
                     </ul>
                     </div>
                     </div>
                     </div>
                     </div>
                     </ngx-tab> -->
            </ngx-tabset>
        </div>
    </div>
</div>
