<div class="main-banner item-bg-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <h4>Soluciones a tu alcance</h4>
                    <h1>Tomorrow <span>Tech</span> S.A.S.</h1>
                    <p>Consultorías tecnológicas, desarrollo web y móvil, hosting, y mucho más. Todo el apoyo que necesita tu negocio o marca.</p>
                    <a (click)="onClick('about')" class="btn btn-primary">Cotiza ahora</a>
                    <a (click)="onClick('work')" class="btn btn-primary view-work">Conoce nuestro trabajo</a>
                </div>
            </div>
        </div>
    </div>
</div>

<app-welcome></app-welcome>

<app-about></app-about>

<app-strategy></app-strategy>

<app-who-we-are></app-who-we-are>

<app-cta></app-cta>

<app-skill></app-skill>

<app-funfacts></app-funfacts>

<app-team></app-team>

<app-how-we-work></app-how-we-work>

<app-services></app-services>

<app-why-we-different></app-why-we-different>

<app-work></app-work>

<!-- <app-pricing></app-pricing> -->

<!-- <app-faq></app-faq> -->

<!-- <app-feedback></app-feedback> -->

<!-- <app-blog></app-blog> -->

<!-- <app-partner></app-partner> -->

<!-- <app-subscribe></app-subscribe> -->

<app-contact></app-contact>
