<div class="main-banner item-bg-one">
    <div class="creative-banner-three"></div>
    <particles [params]="myParams" [style]="myStyle" [width]="width" [height]="height"></particles>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <h4>We Are Creative</h4>
                    <h1>We Are 
                        <ngx-typed-js [strings]="['Professional', 'Passionate', 'Creative', 'Designer', 'Unique', 'Best', 'Support']" [typeSpeed]="100" [loop]="true" [backSpeed]="100" [showCursor]="false">
                            <span class="typing"></span>
                        </ngx-typed-js>
                    </h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <a (click)="onClick('about')" class="btn btn-primary">Get Started</a>
                    <a (click)="onClick('work')" class="btn btn-primary view-work">View Work</a>
                </div>
            </div>
        </div>
    </div>
</div>

<app-welcome></app-welcome>

<app-about></app-about>

<app-strategy></app-strategy>

<app-who-we-are></app-who-we-are>

<app-cta></app-cta>

<app-skill></app-skill>

<app-funfacts></app-funfacts>

<app-team></app-team>

<app-how-we-work></app-how-we-work>

<app-services></app-services>

<app-why-we-different></app-why-we-different>

<app-work></app-work>

<app-pricing></app-pricing>

<app-faq></app-faq>

<app-feedback></app-feedback>

<app-blog></app-blog>

<app-partner></app-partner>

<app-subscribe></app-subscribe>

<app-contact></app-contact>